import axios from "axios";
import { AuthResponseModel } from "../modules/auth";
import { LoginType } from "../models/auth";
import { createSignature } from "../utils/jwtConverter";

const API_URL = process.env.REACT_APP_TRACKER_API_URL
export const LOGIN_URL = `${API_URL}/api/auth/v2/login`

export const loginV2 = async (data : LoginType) => {
    const signature = await createSignature(data, 'web')
    const mappedData = {
       email_or_username: data.email,
       password: data.password,
       client: 'web',
       signature
    }
    return await axios.post<AuthResponseModel>(LOGIN_URL, mappedData)
}

export const userLogout = async (refreshToken: string) => {
  return await axios.post<AuthResponseModel>(`${API_URL}/api/auth/logout`,{ refresh_token: refreshToken })
}

export const forgotPassword = async (email: string) => {
  return await axios.post(`${API_URL}/api/auth/forgot_password`,{ email })
}

export const validateOTP = async ({data} : {data : {email : string, code : string}}) => {
  const res = await axios.post(`${API_URL}/api/auth/validate_otp`, data)
  return res.data.data
}

export const resetPassword = async ({data} : {data : {password : string, reset_token : string}}) => {
  return await axios.post(`${API_URL}/api/auth/reset_password`, data , {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${data.reset_token}`
    }
  })
}