import * as Yup from "yup";

export const requestOTPSchema = Yup.object().shape({
  email : Yup.string().email('Email tidak valid').required('Email harus diisi')
})

export const resetPasswordSchema = Yup.object().shape({
  new_password : Yup.string().required('Password baru harus diisi').min(8, 'Password minimal 8 karakter'),
  confirm_password : Yup.string().required('Harap konfirmasi password baru').test('passwords-match', 'Password tidak sama', function(value) {
    return this.parent.new_password === value
  })
})


export const updatePasswordSchema = Yup.object().shape({
  old_password : Yup.string().required('Password lama harus diisi').min(8, 'Password minimal 8 karakter'),
  new_password : Yup.string().required('Password baru harus diisi').min(8, 'Password minimal 8 karakter').test('passwords-match', 'Password tidak sama', function(value) {
    return this.parent.old_password === value
  })
})