import {KTCard, KTIcon} from '../../../_metronic/helpers'
import {useAppContext} from '../../../_metronic/helpers/AppContext'
import {PageTitle} from '../../../_metronic/layout/core'
import {useEffect, useState} from 'react'
import {FieldHookConfig, Form, Formik, useField, useFormikContext} from 'formik'
import {FormFooterBase} from '../../components/form/FormFooters'
import clsx from 'clsx'
import {FormInputImageV2} from '../../components/form/FormInputs'
import {useChangePassword, useGetProfile, useUpdateProfile} from '../../hooks/profile'
import {uploadWithPresignedUrl} from '../../libs/function'
import {getPresignedURL} from '../../api/presignedURL'
import {baseProfileSchema, changePasswordSchema} from '../../schemas/profile'
import {ChangePasswordType, ProfileType} from '../../models/profile'
import {toast} from 'react-toastify'
import {isEmailExist, isUsernameExist} from '../../utils/duplicateCheck'

export default function ProfilePage() {
  const {role, organizationName, subOrganizationName, nameGroup} = useAppContext()
  const [activeMenu, setActiveMenu] = useState<string>('Ringkasan')
  const [title , setTitle] = useState<string>('Ringkasan')
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(true)
  const initFormPassword = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  }
  const [passwordChangeValue, setPasswordChangeValue] = useState<ChangePasswordType>(initFormPassword)
  const [filePhoto, setFilePhoto] = useState<File | null>(null)
  const {data: profileData} = useGetProfile()
  const {mutate: updateProfile} = useUpdateProfile()
  const {mutate: changePassword, mutateAsync} = useChangePassword()

  const initFormValue = {
    id: 0,
    name: '',
    username: '',
    email: '',
    phone_number: '',
    photo_url: '',
    role_id: 0,
    organization_unit_id: 0,
    sub_organization_unit_id: 0,
  }

  const handleCancel = () => {
    setActiveMenu('Ringkasan');
    setTitle('Ringkasan');
    setPasswordChangeValue(initFormPassword);
  }

  useEffect(() => {
    if (passwordChangeValue.old_password !== '' && passwordChangeValue.new_password !== '' && passwordChangeValue.confirm_password !== '') {
      setIsPasswordDisabled(false)
    }
  }, [passwordChangeValue])

  const handleUpdatePassword = async (values: ChangePasswordType, {setFieldError}: any) => {
    setIsLoading(true)
    if (values.new_password !== values.confirm_password) {
      setFieldError('confirm_password', 'Kata sandi baru & konfirmasi kata sandi tidak sesuai')
      setIsLoading(false)
      return
    } 
    if (values.old_password === values.new_password) {
      setFieldError('new_password', 'Kata sandi baru tidak boleh sama dengan kata sandi lama')
      setIsLoading(false)
      return
    }
    try {
      const mappedValues = {
        old_password: values.old_password,
        new_password: values.new_password
      }
      const result = await mutateAsync(mappedValues)
      console.log('Update berhasil:', result)
      setPasswordChangeValue(initFormPassword)
    } catch (error : any) {
      console.error(error?.response?.status)
      if (error?.response?.status === 400) {
        setFieldError('old_password', 'Kata sandi lama tidak sesuai')
      }
    } finally {
      setIsLoading(false)
    }
  }

  

  const handleUpdateProfile = async (values: ProfileType, {setFieldError}: any) => {
    console.log(values)
    let hasError = false
    setIsUploading(true)
    const mappedValues = {
      ...values,
    }
    try {
      if (values?.username === profileData?.username) {
        delete mappedValues.username
      } else {
        const usernameExis = await isUsernameExist(values.username as string, setFieldError)
        if (usernameExis) hasError = true
      }
      if (values?.email !== profileData?.email) {
        const emailExist = await isEmailExist(values.email as string, setFieldError)
        if (emailExist) hasError = true
      }

      if (mappedValues.sub_organization_unit_id === '' || mappedValues.sub_organization_unit_id === 0) {
        delete mappedValues.sub_organization_unit_id
      }

      if (mappedValues.group_id === '' || mappedValues.group_id === 0) {
        delete mappedValues.group_id
      }

      if (mappedValues?.organization_unit_id === '' || mappedValues?.organization_unit_id === 0) {
        delete mappedValues.organization_unit_id
      }
      const rootFolder = process.env.REACT_APP_AWS_S3_FOLDER
      const fileExtension = values.file ? values.file.name.split('.').pop()?.toLowerCase() : 'jpg'
      if (filePhoto !== null) {
        const nameFile = `${rootFolder}/users/${profileData?.id}.${fileExtension}`
        const presignedURL = await getPresignedURL(nameFile)
        const url = presignedURL.data.split('.com')[0] + '.com'
        await uploadWithPresignedUrl(filePhoto, presignedURL.data)
        mappedValues.photo_url = `${url}/${nameFile}`
      }
      delete mappedValues.file
      if (!hasError) {
        updateProfile(mappedValues)
        setIsEditing(false)
      }
    } catch (error: any) {
      toast.error(error)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title:
              role === 2
                ? organizationName
                : role === 3
                ? subOrganizationName
                : role === 4
                ? `${subOrganizationName} - ${nameGroup}`
                : 'TSM',
            path: '/profile',
            description: `${title}`,
            isActive: true,
            isSeparator: false,
          },
        ]}
      >
        Profile
      </PageTitle>
      <Formik
        initialValues={initFormValue}
        validationSchema={baseProfileSchema}
        onSubmit={handleUpdateProfile}
      >
        <Form>
          <KTCard className='p-8 shadow-sm'>
            <div className='card'>
              <div className='d-flex flex-col md:flex-row '>
                <div className='profile-picture mb-3 w-200px h-200px'>
                  {isEditing ? (
                    <FormInputImageV2
                    name='file'
                    typeInput='image'
                    required={true}
                    photoValue={
                      profileData?.photo_url?.length > 0
                        ? profileData?.photo_url
                        : '/media/svg/humans/custom-2.svg'
                    }
                    size='200px'
                    disabled={!isEditing}
                    setFile={setFilePhoto}
                  />
                  ) : (
                    <img
                      src={ profileData?.photo_url?.length > 0 ? profileData?.photo_url : '/media/svg/humans/custom-2.svg'}
                      alt='img'
                      className='w-200px h-200px shadow-sm'
                      onError={(e: any) => {
                        e.target.src = '/media/svg/humans/custom-2.svg'
                      }}
                    />
                  )}

                </div>
                <div className='col-md-8 px-8'>
                  <div className='row'>
                    <div className='col-md-6 col-12'>
                      <p className='mb-2'>Nama</p>
                      <p className='fw-bold'>{profileData?.name}</p>
                      <p className='mb-2'>Nomor Telepon</p>
                      <p className='fw-bold'>{profileData?.phone_number}</p>
                      <p className='mb-2'>Jenis Kelamin</p>
                      <p className='fw-bold'>
                        {profileData?.gender === 'male'
                          ? 'Laki-laki'
                          : profileData?.gender === 'female'
                          ? 'Perempuan'
                          : '-'}{' '}
                      </p>
                    </div>
                    <div className='col-md-6 col-12'>
                      <p className='mb-2'>Unit Organisasi</p>
                      <p className='fw-bold'>
                        {profileData?.organization_unit?.name?.length > 0
                          ? profileData?.organization_unit?.name
                          : '-'}
                      </p>
                      <p className='mb-2'>Sub Organisasi</p>
                      <p className='fw-bold'>
                        {profileData?.sub_organization_unit?.name?.length > 0
                          ? profileData?.sub_organization_unit?.name
                          : '-'}
                      </p>
                      <p className='mb-2'>Grup / Kloter</p>
                      <p className='fw-bold'>
                        {profileData?.group?.code?.length > 0 ? profileData?.group?.code : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mt-6'>
                <div className='d-flex justify-content-start gap-6'>
                  <button
                    type='button'
                    style={
                      activeMenu === 'Ringkasan'
                        ? {
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: '2px solid #009EF6',
                            color: '#009EF6',
                          }
                        : {border: 'none'}
                    }
                    className={`pb-2 bg-white`}
                    onClick={() => {
                      setActiveMenu('Ringkasan')
                      setTitle('Ringkasan')
                      setPasswordChangeValue(initFormPassword)
                    }}
                  >
                    Ringkasan
                  </button>
                  <button
                    type='button'
                    style={
                      activeMenu === 'Ubah Kata Sandi'
                        ? {
                            borderTop: 'none',
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: '2px solid #009EF6',
                            color: '#009EF6',
                          }
                        : {border: 'none'}
                    }
                    className={`pb-2 bg-white`}
                    onClick={() => {
                      setActiveMenu('Ubah Kata Sandi')
                      setTitle('Ubah Kata Sandi')
                    }}
                  >
                    Kata Sandi
                  </button>
                </div>
              </div>
            </div>
          </KTCard>
            {activeMenu === 'Ringkasan' && (
              <KTCard className='p-8 shadow-sm mt-5'>
              <div className='d-flex justify-content-between align-items-center'>
                <h2>{isEditing ? "Ubah Profil" : "Detail Profil"}</h2>
                <div className={`${isEditing ? 'd-none' : ''}`}>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    onClick={() => {
                      setIsEditing(true)
                      setTitle('Ubah Profil')
                    }}
                  >
                    <i className='bi bi-pencil-square'></i>
                    Ubah
                  </button>
                </div>
              </div>
              <hr className='border-1 border-gray-400 ' />
              <div className='d-none'>
                <FormInputText labelName='id' name='id' value={profileData?.id} disabled />
                <FormInputText
                  labelName='role'
                  name='role_id'
                  value={profileData?.role_id}
                  disabled
                />
                <FormInputText
                  labelName='gender'
                  name='gender'
                  value={profileData?.gender}
                  disabled
                />
                {profileData?.organization_unit?.id && (
                  <FormInputText
                  labelName='organization_unit_id'
                  name='organization_unit_id'
                  value={profileData?.organization_unit?.id}
                  disabled
                />
                )}
                {profileData?.sub_organization_unit?.id && (
                  <FormInputText
                  labelName='sub_organization_unit_id'
                  name='sub_organization_unit_id'
                  value={profileData?.sub_organization_unit?.id ? profileData?.sub_organization_unit?.id : 0}
                  disabled
                />
                )}
              </div>
              <FormInputText
                labelName='Nama'
                name='name'
                value={profileData?.name}
                defaultValue={profileData?.name}
                disabled={!isEditing}
                required
              />
              <FormInputText
                labelName='Username'
                name='username'
                value={profileData?.username}
                defaultValue={profileData?.username}
                disabled={!isEditing}
              />
              <FormInputText
                labelName='Email'
                name='email'
                value={profileData?.email}
                defaultValue={profileData?.email}
                disabled={!isEditing}
              />
              <FormInputText
                labelName='Nomor Telepon'
                name='phone_number'
                value={profileData?.phone_number}
                defaultValue={profileData?.phone_number}
                disabled={!isEditing}
              />

              {isEditing && (
                <FormFooterBase
                  hitClickBack={() => {
                    setIsEditing(false)
                    setTitle('Ringkasan')
                  }}
                  backText='Batal'
                  confirmText={`${isUploading ? 'Loading...' : 'Simpan'}`}
                  isloading={isUploading}
                />
              )}
            </KTCard>
            )}
        </Form>
      </Formik>
        {activeMenu === 'Ubah Kata Sandi' && (
          <KTCard className='p-8 shadow-sm mt-5'>
          <div className='d-flex flex-column gap-4 align-items-center text-center bg-warning p-8 rounded'>
            <h2 className='p-0 m-0'>Mohon Perhatian</h2>
            <p className='p-0 m-0 fs-4'>Wajib melakukan pergantian kata sandi secara berkala.</p>
          </div>
          <div className='mt-6'>
            <div className='d-flex justify-content-between align-items-center my-5'>
              <h2>Ubah Kata Sandi</h2>
            </div>
            <Formik
              initialValues={{old_password: '', new_password: ''}}
              validationSchema={changePasswordSchema}
              onSubmit={handleUpdatePassword}
            >
              <Form>
                <FormInputText
                  labelName='Kata Sandi Lama'
                  name='old_password'
                  isPassword
                  required
                  placeholderText='Masukkan Kata Sandi Lama'
                  onValueChange={(value ) => setPasswordChangeValue({...passwordChangeValue, old_password: value})}
                  value={passwordChangeValue.old_password}
                />
                <FormInputText
                  labelName='Kata Sandi Baru'
                  name='new_password'
                  isPassword
                  required
                  placeholderText='Masukkan Kata Sandi Baru'
                  onValueChange={(value ) => setPasswordChangeValue({...passwordChangeValue, new_password: value})}
                  value={passwordChangeValue.new_password}
                />
                <FormInputText
                  labelName='Konfirmasi Kata Sandi'
                  name='confirm_password'
                  isPassword
                  required
                  placeholderText='Masukkan Konfirmasi Kata Sandi'
                  onValueChange={(value ) => setPasswordChangeValue({...passwordChangeValue, confirm_password: value})}
                  value={passwordChangeValue.confirm_password}
                />
                  <FormFooterBase
                  hitClickBack={() => handleCancel()}
                  backText='Batal'
                  confirmText={`${isUploading ? 'Loading...' : 'Simpan'}`}
                  isloading={isLoading}
                  confirmDisable={isPasswordDisabled}
                />
              </Form>
            </Formik>
          </div>
        </KTCard>
        )}
    </>
  )
}

interface IFormInput {
  labelName: string
  required?: boolean
  placeholderText?: string
  isPassword?: boolean
  onValueChange?: (value: string) => void
}

const errorValidating = (isError: boolean | undefined | '', className?: string) => {
  return clsx(
    'form-control form-control-lg form-control-solid',
    isError && 'border-danger',
    className
  )
}

export const FormInputText = ({
  labelName,
  required = true,
  placeholderText = '',
  onValueChange,
  isPassword = false,
  ...props
}: IFormInput & FieldHookConfig<string>) => {
  const {setFieldValue} = useFormikContext()
  const [valueInput, setValueInput] = useState('')
  const [showPassword, setShowPassword] = useState(!isPassword)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueInput(e.target.value)
    onValueChange?.(e.target.value)
  }

  useEffect(() => {
    setValueInput(props.value)
  }, [props.value])

  useEffect(() => {
    valueInput ? setFieldValue(props.name, valueInput) : setFieldValue(props.name, '')
  }, [valueInput, props.name, setFieldValue])

  const [field, meta] = useField(props)
  return (
    <div className='row mb-6'>
      <label htmlFor={props.name} className='col-lg-2 col-form-label fw-bold fs-6'>
        <span className={required ? 'required' : ''}>{labelName}</span>
      </label>

      <div className='col-lg-10 fv-row position-relative'>
        <input
          {...field}
          name={props.name}
          id={props.name}
          type={`${showPassword ? 'text' : 'password'}`}
          className={errorValidating(meta.error && meta.touched)}
          placeholder={placeholderText !== '' ? placeholderText : `Tambahkan ${labelName}`}
          autoComplete='current-username'
          value={props.disabled ? props.defaultValue : valueInput}
          onChange={handleChange}
          disabled={props.disabled}
          style={
            props.disabled
              ? {backgroundColor: '#ffffff', border: 'none'}
              : {backgroundColor: '#f9f9f9'}
          }
        />
        {!props.disabled && isPassword && (
          <div
            role='button'
            onClick={() => setShowPassword(!showPassword)}
            className='position-absolute end-0 top-25 mx-5'
          >
            <KTIcon className='fs-2' iconName={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} />
          </div>
        )}
        {!props.disabled && meta.error && meta.touched && (
          <p className='text-danger m-0 fw-semibold'>{meta.error}</p>
        )}
      </div>
    </div>
  )
}
