import { Form, Formik, FormikHelpers } from "formik";
import { FormBody } from "../../../components/form/FormBody";
import { FormInputTextColumn } from "../../../components/form/FormInputs";
import { useState } from "react";
import { updatePasswordSchema } from "../../../schemas/auth";

export default function UpdatePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const initResetPassword = {
    old_password : '',
    new_password : '',
  }

  const onResetPasswordSubmit = async (values : any, action : FormikHelpers<any>) => {
    setIsLoading(true)
    console.log(values);
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
    
  }

  return (
    <div className="blank-container">
      <div className="reset-password-container">
        <div className="flex justify-content-center align-items-center w-100"> 
          <div className="w-100 d-flex justify-content-center">
            <img src="media/logos/default.svg" className="w-150px mb-4" alt="Device Tracker Light Logo" />
          </div>
          <Formik
            initialValues={initResetPassword}
            validationSchema={updatePasswordSchema}
            onSubmit={onResetPasswordSubmit}
            >
          <Form>
          <FormBody className="form-container">
          <div>
            <h2 className="fw-bold p-0 m-0">Pembaruan Kata Sandi</h2>
            <p className="p-0 m-0">Untuk kemudahan, silakan ubah kata sandi Anda.</p>
          </div>
          <div className="warning-message-container">
            <p className="p-0 m-0">Anda login sebagai @username</p>
          </div>
          {message && <p className="text-danger m-0 fw-semibold">{message}</p>}
          <FormInputTextColumn isPassword labelName="Kata Sandi Lama" name="old_password" placeholderText="Masukkan kata sandi lama" required={false}  className="m-0"/>
          <FormInputTextColumn isPassword labelName="Kata Sandi Baru" name="new_password" placeholderText="Masukkan kata sandi baru" required={false}  className="m-0"/>
          <button type='submit' className='btn btn-primary me-3 w-100 m-0' disabled={isLoading}> 
          <span className='indicator-progress' style={{display: 'block'}}> Kirim
            {isLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </span>
          </button>
        </FormBody>

          </Form>
          </Formik>

        </div>
      </div>
    </div>
  )
}