import ErrorPageContainer from "./ErrorPageContainer"

const Undermaintain = () => {
  return (
    <ErrorPageContainer
      imageUrl='./media/icons/501.svg'
      title='Under Maintainance'
    >
      <p className='fs-3 text-center'>Our website is currently undergoing scheduled maintenance <br />
      We should be back shortly. Thank you for your patience</p>
    </ErrorPageContainer>
  )
}

export default Undermaintain