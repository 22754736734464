import ErrorPageContainer from "./ErrorPageContainer";

const PageNotFound = () => {
  return (
    <ErrorPageContainer 
      imageUrl='./media/icons/404.svg' 
      title='Ooopps ! Page Not Found' 
      >
      <p className='fs-3 text-center'>The page you are looking  for was not found</p>
    </ErrorPageContainer>
  );
}

export default PageNotFound