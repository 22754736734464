import ErrorPageContainer from "./ErrorPageContainer"

const InternalServerError = () => {
  return (
    <ErrorPageContainer 
      imageUrl='./media/icons/500.svg' 
      title='Ooopps ! Internal Server Error' 
    > 
      <p className='fs-3 text-center'>The server has been deserted for a while. <br />
      Please be patient or try again later.</p>
    </ErrorPageContainer>
  )
}

export default InternalServerError