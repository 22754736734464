import { Form, Formik, FormikHelpers } from "formik";
import AuthContainer from "./AuthContainer";
import { FormBody } from "../../../components/form/FormBody";
import { FormInputTextColumn } from "../../../components/form/FormInputs";
import { useEffect, useState } from "react";
import { forgotPassword, resetPassword, validateOTP } from "../../../api/auth";
import './AuthContainer.css'
import OTPInput from "../../../components/form/OTPInputs";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { requestOTPSchema, resetPasswordSchema } from "../../../schemas/auth";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [otp, setOtp] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [isOTPError, setIsOTPError] = useState(false);
  const [OTPMessage, setOTPMessage] = useState('');
  const [resetToken, setResetToken] = useState('');
  const navigate = useNavigate();
  const initRequestOTP = {
    email : ''
  }

  const initResetPassword = {
    new_password : '',
    confirm_password : '',
  }

  const onRequestOTP = async (values : any, action : FormikHelpers<any>) => {
    setIsLoading(true)
    try {
      await forgotPassword(values.email)
      setEmail(values.email)
      setTimeout(() => {setStep(2); setIsLoading(false)}, 1000);
      setTimeLeft(300);
    } catch (error : AxiosError<any> | any) {
      if (error.response.data.error.includes('sent')) {
        setTimeout(() => {
          setStep(2);
          setEmail(values.email)
          setMessage('Kode OTP sudah dikirim, silahkan cek email anda');}
          , 1000);
        setTimeLeft(300);
      } else if (error.response.status === 404) {
        setMessage('Email tidak ditemukan');
        setIsLoading(false)
      } else {
        setMessage('Terjadi kesalahan, silahkan coba beberapa saat lagi');
        setIsLoading(false)
      }
    }
    setTimeout(() => {
      setMessage('')
    }, 3000)
  }

  const onResendOTP = async () => {
    setIsOTPError(false);
    setIsLoading(true)
    try {
      await forgotPassword(email)
      setTimeLeft(300);
    } catch (error : AxiosError<any> | any) {
      if (error.response.data.error.includes('sent')) {
        setMessage('Kode OTP sudah dikirim, silahkan cek email anda');
        setTimeout(() => {setStep(2); setEmail(email)}, 1000);
        setTimeLeft(300);
      } else {
        setMessage('Terjadi kesalahan, silahkan coba beberapa saat lagi');
      }
    } finally {
      setIsLoading(false)
    }
    setTimeout(() => {
      setMessage('')
    }, 1000)
  } 

  const handleOtpChange = (value: string) => {
    setOtp(value); 
  };

  const onValidateOTPSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    e.preventDefault()
    if (otp === '') {
      setIsOTPError(true);
      setOTPMessage('OTP harus diisi');
      return;
    }
    if (otp.length !== 6) {
      setIsOTPError(true);
      setOTPMessage('OTP yang anda masukkan salah');
      return;
    }
    
    try {
      const res = await validateOTP({data : {email, code : otp}})
      setResetToken(res.reset_token)
      setTimeout(() => {
        setIsLoading(false)
        setStep(3);
      }, 1000);
    } catch (error) {
      setOTPMessage('OTP yang anda masukkan salah');
      setIsLoading(false)
    }
  }

  const onResetPasswordSubmit = async (values : any, action : FormikHelpers<any>) => {
    const data = {
      password: values.new_password,
      reset_token : resetToken,
    }
    try {
      await resetPassword({data})
      showModalSuccess()
    } catch (error) {
      
    }
  }

  const showModalSuccess = () => {
    Swal.fire({
      icon: 'success',
      text: 'Berhasil mengubah kata sandi, silahkan login kembali.',
      showConfirmButton: true
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/auth/login')
      }
    })
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };


  return (
    <AuthContainer>
     <>
     {step === 1 && (
      <Formik
      initialValues={initRequestOTP}
      validationSchema={requestOTPSchema}
      onSubmit={onRequestOTP}
    >
      <Form>
        <FormBody className="form-container">
          <h2 className="fw-bold">Lupa Kata Sandi</h2>
          {message && <p className="text-danger m-0 fw-semibold">{message}</p>}
          <FormInputTextColumn labelName="Masukkan email" name="email" placeholderText="Masukkan email" required={false}  className="m-0"/>
          <button type='submit' className='btn btn-primary me-3 w-100 m-0' disabled={isLoading}> 
          <span className='indicator-progress' style={{display: 'block'}}> Kirim
            {isLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </span>
          </button>
        </FormBody>
      </Form>
    </Formik>
     )}

     {step === 2 && (
      <form className="form-container" onSubmit={(e) => onValidateOTPSubmit(e)}>
          <h2 className="fw-bold">Verifikasi OTP</h2>
          <p className="p-0 m-0">Jangan berikan kode OTP kepada orang lain</p>
          {email && <p className="p-0 m-0">{email}</p>}
          <p className="fw-bold">Masukkan kode OTP</p>
          <OTPInput length={6} onChange={handleOtpChange} isError={isOTPError} messageError={OTPMessage} />
          {timeLeft > 0 ? (
            <div className="timer">{formatTime(timeLeft)}</div>
          ) : (
            <p onClick={onResendOTP}>Kirim ulang OTP</p>
          )}
          <button className="submit-btn w-100">Kirim</button>
      </form>
     )}

     {step === 3 && (
      <Formik
      initialValues={initResetPassword}
      validationSchema={resetPasswordSchema}
      onSubmit={onResetPasswordSubmit}
    >
      <Form>
        <FormBody className="form-container">
          <h2 className="fw-bold">Kata Sandi Baru</h2>
          {message && <p className="text-danger m-0 fw-semibold">{message}</p>}
          <FormInputTextColumn isPassword labelName="Kata sandi baru" name="new_password" placeholderText="Masukkan kata sandi baru" required={false}  className="m-0"/>
          <FormInputTextColumn isPassword labelName="Konfirmasi kata sandi" name="confirm_password" placeholderText="Masukkan konfirmasi" required={false}  className="m-0"/>
          <button type='submit' className='btn btn-primary me-3 w-100 m-0' disabled={isLoading}> 
          <span className='indicator-progress' style={{display: 'block'}}> Kirim
            {isLoading && (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              )}
            </span>
          </button>
        </FormBody>
      </Form>
    </Formik>
     )}
     
     </>
    </AuthContainer>
  );
}
