import { Form, Formik } from "formik";
import { AddUserType } from "../../models/users";
import { useGetGroupList } from "../../hooks/groups";
import { PageTitle } from "../../../_metronic/layout/core";
import { FormBody } from "../../components/form/FormBody";
import { FormInputAsyncSearch, FormInputImageV2, FormInputRadio, FormInputSearch, FormInputText, FormInputTextArea } from "../../components/form/FormInputs";
import { FormFooterBase } from "../../components/form/FormFooters";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAddUser, useUpdateUser, useGetUserByID } from "../../hooks/users";
import { useGetOU, useGetParentOU } from "../../hooks/ou";
import { OptionType } from "../../models/base";
import { useAppContext } from "../../../_metronic/helpers/AppContext";
import {
    userAddAdminSchema, 
    userAddOperatorSchema, 
    userAddSupervisorSchema,
    userEditAdminSchema,
    userEditOperatorSchema,
    userEditSupervisorSchema
} from "../../schemas/users";
import { uploadWithPresignedUrl } from "../../libs/function";
import { FormHeader } from "../../components/form/FormHeaders";
import { OUType } from "../../models/ou";
import { GroupType } from "../../models/group";
import { getPresignedURL } from "../../api/presignedURL";

export const FormUser = () => {
    const { id } = useParams();
    const { role, organizationName, organizationID, subOrganizationID, subOrganizationName, groupName } = useAppContext();
    const { data: dataGroup } = useGetGroupList('umrah');
    const { data: dataUserById } = useGetUserByID(parseInt(id ?? '0'));
    const [filePhoto, setFilePhoto] = useState<File | null>(null);
    const { mutateAsync : addUserAsync } = useAddUser();
    const { mutateAsync : updateUserAsync } = useUpdateUser();
    const [options, setOptions] = useState<OptionType[]>([]);
    const [optionsOU, setOptionsOU] = useState<OptionType[]>([]);
    const [optionParentOU, setOptionParentOU] = useState<OptionType[]>([]);
    const [query, setQuery] = useState<string>('');
    const { data: dataOU } = useGetOU(query);
    const { data: dataParentOU } = useGetParentOU();
    const navigate = useNavigate();
    const [titlePage, setTitlePage] = useState<string>('');
    const [descTitlePage, setDescTitlePage] = useState<string>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const initFormValue: AddUserType = ({
        name: "",
        username: "",
        email: "",
        phone_number: "",
        group_id: 0,
        organization_unit_id: 0,
        sub_organization_unit_id: 0,
        gender: "",
        photo_url: "",
        file: null,
        detail: ""
    });
    const handleValueInputOU = (value: string | number) => {
        setQuery(value as string);
    }

    useEffect(() => {
        if (dataOU) {
            const options: OptionType[] = dataOU.map((ou: OUType) => ({
                value: ou.id,
                label: ou.name,
            }));
            setOptionsOU(options);
        }
    }, [dataOU]);

    useEffect(() => {
        if (dataParentOU) {
            const options: OptionType[] = dataParentOU.map((ou: OUType) => ({
                value: ou.id,
                label: ou.name,
            }));
            setOptionParentOU(options);
        }
    }, [dataParentOU]);

    useEffect(() => {
        if (dataGroup) {
            const data: OptionType[] = dataGroup.map((group: GroupType) => ({
                value: group.id,
                label: group.code,
            }));
            setOptions(data);
        }
    }, [dataGroup]);

    const onFormSubmit = async (values: AddUserType , {setFieldError} : any ) => {
        setIsUploading(true);
        const rootFolder = process.env.REACT_APP_AWS_S3_FOLDER;
        const OUName = role !== 1 ? organizationName : optionParentOU?.find((ou: OptionType) => ou.value === values.organization_unit_id)?.label;
        try {
            const fileExtension = values.file ? values.file.name.split('.').pop()?.toLowerCase() : 'jpg';
            let photoUrl = dataUserById?.photo_url ?? '';
            if (filePhoto !== null) {
                const nameFile = `${rootFolder}/users/${OUName.toLowerCase().replace(/\s+/g, '-')}/${values.username}.${fileExtension}`;
                const presignedURL = await getPresignedURL(nameFile);
                await uploadWithPresignedUrl(filePhoto, presignedURL.data );
                const url = presignedURL.data.split('.com')[0] + '.com'; 
                photoUrl = (`${url}/${nameFile}`);
            }
            delete values.file
            if ( values.username === dataUserById?.username) {
                delete values.username
            }
            if(role === 1){
                delete values.sub_organization_unit_id
                delete values.group_id
            } else if(role === 2){
                delete values.organization_unit_id
                delete values.group_id
            } else {
                delete values.organization_unit_id
                delete values.sub_organization_unit_id
            }
            const mappedValues: AddUserType = {
                ...values,
                photo_url: photoUrl,
                detail: values.detail ?? '',
            }
            if (id) {
                await updateUserAsync({ id: parseInt(id ?? '0'), data: mappedValues });
            } else {
                await addUserAsync({ data: mappedValues });
            }
            // action.resetForm();
            navigate('/users');
        } catch (error : any) {
            console.log(error?.response?.status)
            if (error?.response?.status === 409) {
                console.log(error?.response?.data?.error)
                if (error?.response?.data?.error?.toLowerCase().includes('username')) {
                    setFieldError('username', 'Username sudah digunakan');
                }
                if (error?.response?.data?.error?.toLowerCase().includes('email')) {
                    setFieldError('email', 'email sudah digunakan');
                }
            }
        } finally {
            setIsUploading(false);
        }
    }

    useEffect(() => {
        if (role === 1) {
            setTitlePage('')
            setDescTitlePage('Admin');
        } else if (role === 2) {
            setTitlePage(organizationName ?? 'Unkown Organization Name')
            setDescTitlePage('Supervisor');
        } else if (role === 3) {
            setTitlePage(subOrganizationName ?? 'Unkown Sub Organization Name')
            setDescTitlePage('Operator');
        } else if (role === 4) {
            setTitlePage(`${subOrganizationName ?? 'Unkown Sub Organization Name'} - ${groupName ?? 'Unkown Group Name'}`)
        }
    }, [role, organizationName, subOrganizationName, groupName]);

    return (
        <>
            <PageTitle breadcrumbs={[{
                title: role === 1 ? 'TSM' : titlePage,
                path: '/users',
                description: id ? 'Edit Pengguna ' + descTitlePage : 'Tambah Pengguna ' + descTitlePage,
                isActive: true,
                isSeparator: false
            }]}>Manajemen Akun</PageTitle>
            <div className="card mb-5 mx-xl-10">
                <Formik
                    initialValues={initFormValue}
                    validationSchema={
                        id
                            ? (role === 1 ? userEditAdminSchema
                               : role === 2 ? userEditSupervisorSchema
                               : userEditOperatorSchema)
                            : (role === 1 ? userAddAdminSchema
                               : role === 2 ? userAddSupervisorSchema
                               : userAddOperatorSchema)
                    }
                    onSubmit={onFormSubmit}
                >
                    <Form>
                        <FormHeader title={id ? 'Edit Pengguna - ' + descTitlePage : 'Tambah Pengguna - ' + descTitlePage} />
                        <FormBody>
                            {role !== 3 && role !== 4 ?
                                <FormInputSearch
                                    name="organization_unit_id"
                                    labelName="Induk Organisasi"
                                    options={optionParentOU}
                                    value={role === 1 ? id ? dataUserById?.organization_unit?.id : null : organizationID}
                                    label={role === 1 ? id ? dataUserById?.organization_unit?.name : null : organizationName}
                                    disabled={role !== 1 ? true : false}
                                />
                                : <></>
                            }
                            {role !== 1 ?
                                <FormInputAsyncSearch
                                    name="sub_organization_unit_id"
                                    labelName="Sub Unit Organisasi"
                                    value={role === 2 ? id ? dataUserById?.sub_organization_unit.id : null : subOrganizationID}
                                    label={role === 2 ? id ? dataUserById?.sub_organization_unit.name : null : subOrganizationName}
                                    options={optionsOU}
                                    onValueChange={handleValueInputOU}
                                    disabled={role !== 2 ? true : false}
                                />
                                : <></>}
                            {role !== 1 && role !== 2 && role !== 4 ?
                                <FormInputSearch
                                    name="group_id"
                                    labelName="Group"
                                    options={options}
                                    value={dataUserById?.group.id}
                                    label={dataUserById?.group.code}
                                    disabled={role !== 3 ? true : false}
                                    hidden={true}
                                />
                                : <></>
                            }
                            <FormInputText name="username" labelName="Username" required={true} value={dataUserById?.username}/>
                            <FormInputText name="name" labelName="Nama" required={true} value={dataUserById?.name}/>
                            <FormInputImageV2 name="file" labelName="Foto" typeInput="image" required={true} photoValue={dataUserById?.photo_url ?? ''} setFile={setFilePhoto} />
                            <FormInputText name="email" labelName="Email" value={dataUserById?.email} />
                            <FormInputText name="phone_number" labelName="Nomor Telepon" value={dataUserById?.phone_number} />
                            <FormInputRadio
                                name="gender"
                                labelName="Jenis Kelamin"
                                values={["male", "female"]}
                                valuesLabel={["Laki-laki", "Perempuan"]}
                                checkedValue={dataUserById?.gender}
                            />
                            <FormInputTextArea name="detail" labelName="Detail (Optional)" required={false} value={dataUserById?.detail ?? ''}/>

                        </FormBody>
                        <FormFooterBase linkTo={'/users'} confirmText={isUploading ? 'Loading...' : 'Simpan'} confirmDisable={isUploading} isloading={isUploading} />
                    </Form>
                </Formik>
            </div>
        </>
    )
}