import React, { useRef } from "react";
import "./OTPInput.css";

interface OTPInputProps {
  length: number;
  onChange: (value: string) => void; 
  isError?: boolean
  messageError?: string
}

const OTPInput: React.FC<OTPInputProps> = ({ length, onChange, isError, messageError }) => {
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target;

    // Abaikan input yang bukan angka
    if (!/^[0-9]*$/.test(value)) {
      e.target.value = "";
      return;
    }

    // Hanya gunakan karakter pertama jika pengguna paste
    if (value.length > 1) {
      e.target.value = value.charAt(0);
    }

    // Pindah fokus ke input berikutnya jika ada nilai
    if (value && index < length - 1) {
      inputsRef.current[index + 1]?.focus();
    }

    // Gabungkan nilai dari semua input dan kirim ke onChange
    const otpValue = inputsRef.current.map((input) => input?.value || "").join("");
    onChange(otpValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !e.currentTarget.value && index > 0) {
      // Pindah fokus ke input sebelumnya jika Backspace ditekan
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <>
    <div className="otp-container">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          ref={(el) => (inputsRef.current[index] = el)}
          type="text"
          placeholder="-"
          maxLength={1}
          className="otp-input"
          onChange={(e) => handleInputChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={isError ? { border: "1px solid red" } : {}}
        />
      ))}
    </div>
    {messageError && <p className="text-danger m-0 p-0 fw-semibold">{messageError}</p>}
    </>
  );
};

export default OTPInput;