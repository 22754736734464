import { Modal } from "react-bootstrap"
import { useGetOUById } from "../../../hooks/ou"
import ListItem from "../../../components/ListItem";
import "./ModalOrganizationUnitDetail.css"

interface Props {
    show: boolean,
    onClose: () => void,
    id: number
}
/**
 * Renders a modal component for displaying organization unit details.
 *
 * @param {Props} show - Determines whether the modal is visible or hidden.
 * @param {Props} onClose - Callback function to close the modal.
 * @return {JSX.Element} The rendered modal component.
 */
const ModalOrganizationUnitDetail = ({ show, id, onClose }: Props) => {
    const { data: OUByID } = useGetOUById(String(id));
    const { data: OUByParentID } = useGetOUById(String(OUByID?.parent_id ?? 0));
    const borderStyle = 'dotted-border';

    return (
        <>
            <Modal show={show} onHide={onClose} size="lg" centered>
                <Modal.Body>
                    <div className="d-flex justify-content-between mb-4" >
                    <div>
                        <h3 className="h4 text-black text-bold">Detail Unit Organisasi</h3>
                    </div>
                    <div>
                        <i className="bi bi-x-circle-fill" onClick={() => onClose()}></i>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="text-black-600 fw-bold bg-gray-200 d-inline-block px-1 mb-3 rounded">Data Unit Organisasi</p>
                            <div className="card p-10 shadow-sm">
                                <ListItem label="Kode" data={OUByID?.code ?? '-'} className={borderStyle} />
                                {OUByID?.parent_id === 0 ? (
                                    <>
                                        <ListItem label="Unit Organisasi" data={OUByID?.name ?? '-'} className={borderStyle} />
                                    </>
                                ) : (
                                    <>
                                        <ListItem label="Unit Organisasi" data={OUByParentID?.name ?? '-'} className={borderStyle} />
                                        <ListItem label="Sub Organisasi" data={OUByID?.name ?? '-'} className={borderStyle} />
                                    </>
                                )}
                                <ListItem label="Kategori" data={OUByID?.type === 'hajj' ? 'Haji' : 'Umrah'} className={borderStyle} />
                                <ListItem label="Embarkasi" data={'-'} className={borderStyle} />
                            </div>
                        </div>
                        <div className="col-6">
                            <p className="text-black-600 fw-bold bg-gray-200 d-inline-block px-1 mb-3 rounded">Penanggung Jawab</p>
                            <div className="card p-10 shadow-sm">
                                <ListItem label="Nama" data={OUByID?.pic ?? '-'} className={borderStyle} />
                                <ListItem label="Nomor Telepon" data={OUByID?.phone_number ? OUByID?.phone_number?.toString() : '-'} className={borderStyle} />
                                <ListItem label="Email" data={OUByID?.email ?? '-'} className={borderStyle} />
                                <ListItem label="Alamat" data={OUByID?.address ?? '-'} className={borderStyle} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalOrganizationUnitDetail
