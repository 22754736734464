import { FieldConfig, FieldHookConfig, GenericFieldHTMLAttributes, useField, useFormikContext } from 'formik'
import clsx from 'clsx'
import { ReactNode, useEffect, useState, useRef } from 'react'
import Select, { MultiValue, PropsValue } from 'react-select'
import AsyncSelect from 'react-select/async'
import { Link } from 'react-router-dom'
import "./dropzone.css"
import moment from 'moment'
import Download from '../Download';
import { OptionType } from '../../models/base'
import CropEasy from '../cropImage/cropEasy'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
/**
 * 
 * Docs : 
 * Input using seperated components using form useFiled
 * Provide 'name' prop when using <FormInputText /> or others and 'name' should match the model key name
 * 
 */


interface IFormInput {
  /** Label text for form input */
  labelName: string
  /** Show required label beside label text, by default its 'true' */
  required?: boolean
  /** Placeholder text for input type, by default 'Ketikkan + {labelName}'*/
  placeholderText?: string
  // Input type, by default 'text'
  isPassword?: boolean

  onValueChange?: (value: string | number) => void
}

interface IFormInputFile {
  /** Label text for form input */
  labelName: string
  /** Show required label beside label text, by default its 'true' */
  required?: boolean
  /** Placeholder text for input type, by default 'Ketikkan + {labelName}'*/
  placeholderText?: string
  /** Input type, by default 'text' */
  typeInput: string

  onValueChange?: (value: File | null) => void
}

interface IFormInputImage{
  /** Label text for form input */
  labelName?: string
  /** Show required label beside label text, by default its 'true' */
  required?: boolean
  /** Placeholder text for input type, by default 'Ketikkan + {labelName}'*/
  placeholderText?: string
  /** Input type, by default 'text' */
  typeInput: string
  photoValue: string
  size ?: string
  onValueChange?: (value: File | null) => void
  setFile?: React.Dispatch<React.SetStateAction<File | null>>
}

interface IFormInputTextarea extends IFormInput {
  /** Give total rows needed for textarea, by default '5' */
  rows?: number
}

interface IFormInputSelectWithChildren extends IFormInput {
  children: ReactNode
}

interface IFormInputSelectReact extends IFormInput {
  options: Array<OptionType>
  label?: string
  onValueChange?: (value: string | number) => void
  onSelectedValue?: (value: string | number) => void
}

interface IFormRadio extends IFormInput {
  values: Array<string | number>
  valuesLabel: Array<string | number>
  checkedValue?: string
  onSelectedValueChange?: (value: string) => void;
}

interface IInputLabel {
  id?: string
  labelName: string
  required?: boolean
}

type ExtendedFieldHookConfig<T> = GenericFieldHTMLAttributes & FieldConfig<T> & {
  meta: {
    error?: string;
    touched?: boolean;
  };
};

const errorValidating = (isError: boolean | undefined | '', className?: string) => {
  return clsx('border form-control form-control-lg form-control-solid', isError && 'border-danger', className)
}

const InputLabel = ({ id, labelName, required = true }: IInputLabel) => (
  <label htmlFor={id} className='col-lg-4 col-form-label fw-bold fs-6'>
    <span className={required ? 'required' : ''}>{labelName}</span>
  </label>
)

export const FormInputTextColumn = ({
  labelName,
  required = true,
  placeholderText = '',
  onValueChange,
  isPassword = false,
  ...props
}: IFormInput & FieldHookConfig<string>) => {
  const { setFieldValue } = useFormikContext();
  const [valueInput, setValueInput] = useState("");
  const [showPassword, setShowPassword] = useState(!isPassword);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueInput(e.target.value)
    onValueChange?.(e.target.value); 
  }

  useEffect(() => {
    setValueInput(props.value)
  }, [props.value])

  useEffect(() => {
    valueInput ? setFieldValue(props.name, valueInput) : setFieldValue(props.name, '')
  }, [valueInput, props.name, setFieldValue])

  const [field, meta] = useField(props)
  return (
    <div className='row'>
      <label htmlFor={props.name} className='col-12 col-form-label fw-bold fs-6'>
        <span className={required ? 'required' : ''}>{labelName}</span>
      </label>

      <div className='col-12 fv-row position-relative'>
        <input
          {...field}
          name={props.name}
          id={props.name}
          type={`${showPassword ? 'text' : 'password'}`}
          className={errorValidating(meta.error && meta.touched)}
          placeholder={placeholderText !== '' ? placeholderText : `Tambahkan ${labelName}`}
          autoComplete='current-username'
          value={props.disabled ? props.defaultValue : valueInput}
          onChange={handleChange}
          disabled={props.disabled}
          style={
            props.disabled
              ? {backgroundColor: '#ffffff', border: 'none'}
              : {backgroundColor: '#f9f9f9'}
          }
        />
        {!props.disabled && isPassword && (
          <div
            role='button'
            onClick={() => setShowPassword(!showPassword)}
            className='position-absolute end-0 top-25 mx-5'
          >
            <KTIcon className='fs-2' iconName={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} />
          </div>
        )}
        {!props.disabled && meta.error && meta.touched && (
          <p className='text-danger m-0 fw-semibold'>{meta.error}</p>
        )}
      </div>
    </div>
  )
}

export const FormInputText = ({
  labelName,
  required = true,
  placeholderText = '',
  onValueChange,
  ...props
}: IFormInput & FieldHookConfig<string>) => {
  const { setFieldValue } = useFormikContext();
  const [valueInput, setValueInput] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueInput(e.target.value)
    onValueChange?.(e.target.value); 
  }

  useEffect(() => {
    setValueInput(props.value)
  }, [props.value])

  useEffect(() => {
    valueInput ? setFieldValue(props.name, valueInput) : setFieldValue(props.name, '')
  }, [valueInput, props.name, setFieldValue])

  const [field, meta] = useField(props)
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />

      <div className='col-lg-8 fv-row'>
        <input
          {...field}
          name={props.name}
          id={props.name}
          type='text'
          className={errorValidating(meta.error && meta.touched)}
          placeholder={placeholderText !== '' ? placeholderText : `Ketikkan ${labelName}`}
          autoComplete='current-username'
          value={valueInput}
          onChange={handleChange}
          disabled={props.disabled}
          style={props.disabled ? {backgroundColor: '#e0e0e0'} : {backgroundColor: '#f9f9f9'}}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

/**
 * Renders a form input file component.
 *
 * @param {string} labelName - The name of the label for the input.
 * @param {boolean} required - Indicates if the input is required. Defaults to true.
 * @param {string} placeholderText - The placeholder text for the input. Defaults to an empty string.
 * @param {IFormInput & ExtendedFieldHookConfig<File>} props - Additional props for the component.
 * @return {JSX.Element} - The rendered form input file component.
 * 
 */

export const FormInputFile = ({
  labelName,
  required = true,
  typeInput = "",
  placeholderText = '',
  onValueChange,
  ...props
}: IFormInputFile & ExtendedFieldHookConfig<File>) => {
  const [fileError, setFileError] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { setFieldValue, errors, touched } = useFormikContext<FormValues>();
  const isError = touched[props.name] && errors[props.name];
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.name.endsWith('.xlsx') || file.name.endsWith('.csv')) {
        onValueChange?.(file);
        setFileError('');
        setSelectedFile(file);
        setFieldValue(props.name, file);
      } else {
        setFileError('File harus berformat .xlsx atau .csv');
      }
    } else {
      setFileError('');
      setSelectedFile(null);
      setFieldValue(props.name, null);
    }
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFieldValue(props.name, null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; 
    }
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const filesText = selectedFile ? <p key={selectedFile.name}>{selectedFile.name}</p> : null;

  let fileName = "";
  switch (typeInput) {
    case "jamaah":
      fileName = process.env.REACT_APP_AWS_S3_TEMPLATE_BATCH_JAMAAH as string;
      break;
    case "device":
      fileName = process.env.REACT_APP_AWS_S3_TEMPLATE_BATCH_DEVICE as string;
      break;
    case "add-inventory":
      fileName = process.env.REACT_APP_AWS_S3_TEMPLATE_BATCH_ADD_INVENTORY as string;
      break;
    case "transfer-inventory":
      fileName = process.env.REACT_APP_AWS_S3_TEMPLATE_BATCH_TRANSFER_INVENTORY as string;
      break;
    default:
      fileName = process.env.REACT_APP_AWS_S3_TEMPLATE_BATCH_JAMAAH as string;
      break;
  }

  return (
    <div className={`row mb-6 ${fileError ? 'border-red' : ''}`}>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <div
          onClick={openFileInput}
          className={selectedFile ? 'dropzone' : fileError ? 'dropzone-reject' : 'dropzone'}
        >
          {selectedFile ? (
            <div>
              <i className="bi bi-filetype-xlsx fs-1"></i>
              <p className='text-black-100 fs-6'>{filesText}</p>
              <button onClick={removeFile}>Remove File</button>
            </div>
          ) : (
            <div>
              <i className="bi bi-upload fs-1"></i>
              <p className='text-black-50 fs-4' style={{ marginTop: "15px", marginLeft: "10px"}}>Unggah</p>
            </div>
          )}
        </div>
        <input
          ref={fileInputRef}
          id={`fileInput_${props.name}`}
          type='file'
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        {isError &&  <p className='text-danger m-0 fw-semibold'>{errors[props.name]}</p>}
        <p className='pt-3'><i className="bi bi-info-circle-fill"></i> Format Excel/CSV</p>
        <Link to={''} onClick={() => Download(fileName, typeInput)} className='text-black'><i className="bi bi-download"></i> Download Template</Link>
        {props.meta.error && props.meta.touched && (
          <p className='text-danger m-0 fw-semibold'>{props.meta.error}</p>
        )}
      </div>
    </div>
  );
};



export const FormInputNumber = ({
  labelName,
  required,
  placeholderText = '',
  ...props
}: IFormInput & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />

      <div className='col-lg-8 fv-row'>
        <input
          {...field}
          name={props.name}
          id={props.name}
          type='number'
          className={errorValidating(meta.error && meta.touched)}
          placeholder={placeholderText !== '' ? placeholderText : `Ketikkan ${labelName}`}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

export const FormInputTextArea = ({
  labelName,
  required = true,
  rows = 5,
  placeholderText = '',
  ...props
}: IFormInputTextarea & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [valueInput, setValueInput] = useState<string>('');
  const handleChange = (e: string) => {
    setValueInput(e);
    setFieldValue(props.name, e);
  }

  useEffect(() => {
    setFieldValue(props.name, props.value);
    setValueInput(props.value);
  },[props.name, props.value, setFieldValue])
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <textarea
          {...field}
          name={props.name}
          className={errorValidating(meta.error && meta.touched)}
          placeholder={placeholderText !== '' ? placeholderText : `Ketikkan ${labelName}`}
          rows={rows}
          value={valueInput}
          onChange={(e) => handleChange(e.target.value)}
        >{props.value}</textarea>
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

export const FormInputDate = ({ labelName, required, onValueChange, ...props }: IFormInput & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  const [valueInput, setValueInput] = useState<any>();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(props.name, moment(props.value).format('YYYY-MM-DD'));
    setValueInput(props.value);
  },[props.name, props.value, setFieldValue])

  const handleChange = (e: any) => {
    setValueInput(e)
    setFieldValue(props.name, e);
  }
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <input
          {...field}
          name={props.name}
          type='date'
          placeholder='Pilih Tanggal'
          value={moment(valueInput).format('YYYY-MM-DD')}
          className={errorValidating(meta.error && meta.touched)}
          onChange={(e) => {
            handleChange(e.target.value);
            onValueChange?.(e.target.value);
          }}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

export const InputDate = ({ labelName, required, onValueChange, ...props }: IFormInput & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  const [valueInput, setValueInput] = useState<any>();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(props.name, moment(props.value).format('YYYY-MM-DD'));
    setValueInput(props.value);
  },[props.name, props.value, setFieldValue])

  const handleChange = (e: any) => {
    setValueInput(e)
    setFieldValue(props.name, e);
  }
  return (
    <div className='row mb-6'>
      <div className='col-lg-12 fv-row'>
        <input
          {...field}
          name={props.name}
          type='date'
          placeholder='Pilih Tanggal'
          value={moment(valueInput).format('YYYY-MM-DD')}
          className={errorValidating(meta.error && meta.touched)}
          onChange={(e) => {
            handleChange(e.target.value);
            onValueChange?.(e.target.value);
          }}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

export const FormInputTime = ({ labelName, required = true, ...props }: IFormInput & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <input
          {...field}
          name={props.name}
          type='time'
          className={errorValidating(meta.error && meta.touched)}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

// TODO : Implement Select Option
export const FormInputSelect: React.FC<IFormInputSelectWithChildren & FieldHookConfig<string>> = ({
  labelName,
  required,
  children,
  ...props
}) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext();
  const [valueInput, setValueInput] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValueInput(e.target.value)
  }

  useEffect(() => {
    setValueInput(props.value)
  }, [props.value])

  useEffect(() => {
    valueInput ? setFieldValue(props.name, valueInput) : setFieldValue(props.name, '')
  }, [valueInput, props.name, setFieldValue])
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <select
          {...field}
          name={props.name}
          value={valueInput}
          className={errorValidating(meta.error && meta.touched, 'form-select')}
          onChange={handleChange}
        >
          {children}
        </select>
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}
export const FormInputRadio: React.FC<IFormRadio & FieldHookConfig<string>> = ({
  labelName,
  required,
  values,
  valuesLabel,
  checkedValue,
  onSelectedValueChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [selectedValue, setSelectedValue] = useState<string>('');

  useEffect(() => {
    setSelectedValue(checkedValue ?? '');
  },[checkedValue])

  useEffect(() => {
    selectedValue ? setFieldValue(props.name ?? '', selectedValue ?? '') : setFieldValue(props.name ?? '', '')
    if(selectedValue) {
      onSelectedValueChange?.(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, props.name, setFieldValue])


  const handleChange = (e: string) => {
    setSelectedValue(e ?? '');
    if (onSelectedValueChange) {
      onSelectedValueChange(e ?? '');
    }
  }
  return ( 
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row d-flex align-items-center gap-3'>
        {values.map((v, idx) => (
          <div key={idx} className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm'>
            <input
              key={idx}
              {...field}
              type='radio'
              id={`radio-id-${idx}-${props.name}`}
              className='form-check-input'
              name={props.name}
              value={v}
              checked={selectedValue === v}
              onChange={(e) => handleChange(e.target.value)}
              disabled={props.disabled}
              style={props.disabled ? {backgroundColor: '#e0e0e0'} : {backgroundColor: ''}}
            />
            <label key={`radio-id-${idx}`} htmlFor={`radio-id-${idx}`} className='form-check-label'>
              {valuesLabel[idx]}
            </label>
          </div>
        ))}
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

export const FormInputPassword = ({
  labelName,
  required = true,
  placeholderText = '',
  ...props
}: IFormInput & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />

      <div className='col-lg-8 fv-row'>
        <input
          {...field}
          name={props.name}
          id={props.name}
          type='password'
          className={errorValidating(meta.error && meta.touched)}
          placeholder={placeholderText !== '' ? placeholderText : `Masukkan ${labelName}`}
          autoComplete='current-password'
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  )
}

export const FormInputKloter: React.FC<IFormInputSelectReact & FieldHookConfig<string>> = ({
  labelName,
  required,
  options,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  useEffect(() => {
    setSelectedOption({
      value: props.value ?? "",
      label: label ?? "Pilih..."
    })
  }, [props.value, label])

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    selectedOption?.value ?
      setFieldValue(props.name, selectedOption?.value) :
      setFieldValue(props.name, '')
  }, [selectedOption?.value, props.name, setFieldValue])
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <Select
          {...field}
          name={props.name}
          options={options ?? []}
          defaultValue={props.defaultValue as PropsValue<OptionType> | undefined}
          value={selectedOption ?? []}
          onChange={(value) => {
            setSelectedOption(value);
          }}
          placeholder='Pilih Kloter'
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              borderColor: meta.error && meta.touched ? 'red' : '#0000',
              backgroundColor: '#f8f9f8',
              height: '45px',
              fontWeight: 'light',
              fontSize: '14px'
            })
          }}
          className={''}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  );
};

export const FormInputKloterMulti: React.FC<IFormInputSelectReact & FieldHookConfig<string>> = ({
  labelName,
  required,
  options,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [selectedOption, setSelectedOption] = useState<OptionType[]>([]);
  useEffect(() => {
    setSelectedOption(props.value ? [{
      value: props.value ?? "",
      label: label ?? ""
    }] : [])
  }, [props.value, label])
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    const selectedValues = selectedOption ? selectedOption.map((option) => option.value) : [];
    setFieldValue(props.name, selectedValues ?? "");
  }, [selectedOption, props.name, setFieldValue]);
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <Select
          {...field}
          isMulti
          name={props.name}
          options={options || []}
          defaultValue={props.defaultValue as PropsValue<OptionType> | undefined}
          value={selectedOption}
          onChange={(value: MultiValue<OptionType>) => {
            setSelectedOption(Array.isArray(value) ? value : []);
          }}
          placeholder='Pilih Kloter'
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              borderColor: meta.error && meta.touched ? 'red' : '#0000',
              backgroundColor: props.disabled ? '#e0e0e0' : '#f9f8f8',
              height: '45px',
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              
            }),
            placeholder: (baseStyle) => ({
              ...baseStyle,
              color: '#9799a1'
            }),
            option: (baseStyle, props) => ({
             ...baseStyle,
             fontWeight: '500',
             fontSize: '1.15rem',
             fontFamily: 'Poppins',
             color: '#8b8c99',
             backgroundColor: props.isSelected ? '#deedff' : 'white' ,
            }),
            singleValue: (baseStyle) => ({
              ...baseStyle,
              marginLeft: '10px',
              color: '#676b7e',
              fontWeight: '500',
            }),
            input: (baseStyle) => ({
              ...baseStyle,
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              marginLeft: '10px',
            })
          }}
          className={''}
          isDisabled={props.disabled}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  );
};

export const FormInputSearch: React.FC<IFormInputSelectReact & FieldHookConfig<string>> = ({
  labelName,
  required,
  options,
  label,
  onValueChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  useEffect(() => {
    setSelectedOption({
      value: props.value ?? '',
      label: label ?? `Ketikkan ${labelName}`
    })
  }, [props.value, label, labelName])

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    selectedOption?.value ?
      setFieldValue(props.name, selectedOption?.value) :
      setFieldValue(props.name, '')
  }, [selectedOption?.value, props.name, setFieldValue])
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <Select
          {...field}
          name={props.name}
          options={options || []}
          defaultValue={props.defaultValue as PropsValue<OptionType> | undefined}
          value={selectedOption}
          onChange={(value) => {
            setSelectedOption(value);
            onValueChange?.(value?.value ? value?.value : 0);
          }}
          placeholder={`Ketikkan ${labelName}`}
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              borderColor: meta.error && meta.touched ? 'red' : '#0000',
              backgroundColor: props.disabled ? '#e0e0e0' : '#f9f8f8',
              height: '45px',
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              
            }),
            placeholder: (baseStyle) => ({
              ...baseStyle,
              color: '#9799a1'
            }),
            option: (baseStyle, props) => ({
             ...baseStyle,
             fontWeight: '500',
             fontSize: '1.15rem',
             fontFamily: 'Poppins',
             color: '#8b8c99',
             backgroundColor: props.isSelected ? '#deedff' : 'white' ,
            }),
            singleValue: (baseStyle) => ({
              ...baseStyle,
              marginLeft: '10px',
              color: '#676b7e',
              fontWeight: '500',
            }),
            input: (baseStyle) => ({
              ...baseStyle,
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              marginLeft: '10px',
            })
          }}
          className={''}
          isDisabled={props.disabled}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  );
};

export const FormInputAsyncSearch: React.FC<IFormInputSelectReact & FieldHookConfig<string>> = ({
  labelName,
  required,
  options,
  label,
  onValueChange,
  onSelectedValue,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  useEffect(() => {
    setSelectedOption({
      value: props.value ?? '',
      label: label ?? `Pilih ${labelName}`
    })
  }, [props.value, label, labelName])

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    selectedOption?.value ?
      setFieldValue(props.name, selectedOption?.value) :
      setFieldValue(props.name, '')
  }, [selectedOption?.value, props.name, setFieldValue])

  const filterData = (inputValue: string) => {
    return options.filter((i) => 
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadOptions = (
    inputValue: string,
    callback: (options: OptionType[]) => void
  ) => {
    onValueChange?.(inputValue);
    setTimeout(() => {
      callback(filterData(inputValue));
    }, 1000);
  };
  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <AsyncSelect
          {...field}
          cacheOptions
          defaultOptions={options || []}
          name={props.name}
          loadOptions={loadOptions}
          defaultValue={props.defaultValue as PropsValue<OptionType> | undefined}
          value={selectedOption}
          onChange={(value) => {
            setSelectedOption(value);
            onSelectedValue?.(value?.value ?? 0);
          }}
          placeholder={`Ketikkan ${labelName}`}
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              borderColor: meta.error && meta.touched ? 'red' : '#0000',
              backgroundColor: props.disabled ? '#e0e0e0' : '#f9f8f8',
              height: '45px',
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              
            }),
            placeholder: (baseStyle) => ({
              ...baseStyle,
              color: '#9799a1'
            }),
            option: (baseStyle, props) => ({
             ...baseStyle,
             fontWeight: '500',
             fontSize: '1.15rem',
             fontFamily: 'Poppins',
             color: '#8b8c99',
             backgroundColor: props.isSelected ? '#deedff' : 'white' ,
            }),
            singleValue: (baseStyle) => ({
              ...baseStyle,
              marginLeft: '10px',
              color: '#676b7e',
              fontWeight: '500',
            }),
            input: (baseStyle) => ({
              ...baseStyle,
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              marginLeft: '10px',
            })
          }}
          className={''}
          isDisabled={props.disabled}
        />
        {meta.error && meta.touched && <p className='text-danger m-0 fw-semibold'>{meta.error}</p>}
      </div>
    </div>
  );
};

export const FormSelectSearch: React.FC<IFormInputSelectReact & FieldHookConfig<string>> = ({
  labelName,
  required,
  options,
  label,
  onValueChange,
  onSelectedValue,
  ...props
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  useEffect(() => {
    setSelectedOption({
      value: props.value ?? '',
      label: label ?? `Pilih ${labelName}`
    })
  }, [props.value, label, labelName])

  const filterData = (inputValue: string) => {
    return options.filter((i) => 
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadOptions = (
    inputValue: string,
    callback: (options: OptionType[]) => void
  ) => {
    onValueChange?.(inputValue);
    setTimeout(() => {
      callback(filterData(inputValue));
    }, 1000);
  };
  return (
    <div className='row mb-6'>
      <label htmlFor={props.name} className='col-12 col-form-label fw-bold fs-6 my-0 pt-0 pb-2'>
        <span className={required ? 'required' : ''}>{labelName}</span>
      </label>
      <div className='col-12 fv-row'>
        <AsyncSelect
          cacheOptions
          defaultOptions={options || []}
          name={props.name}
          loadOptions={loadOptions}
          defaultValue={props.defaultValue as PropsValue<OptionType> | undefined}
          value={selectedOption}
          onChange={(value) => {
            setSelectedOption(value);
            onSelectedValue?.(value?.value ?? 0);
          }}
          placeholder={`Ketikkan ${labelName}`}
          styles={{
            control: (baseStyle) => ({
              ...baseStyle,
              backgroundColor: props.disabled ? '#e0e0e0' : '#f9f8f8',
              border : 'none',
              height: '45px',
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              
            }),
            placeholder: (baseStyle) => ({
              ...baseStyle,
              color: '#9799a1'
            }),
            option: (baseStyle, props) => ({
             ...baseStyle,
             fontWeight: '500',
             fontSize: '1.15rem',
             fontFamily: 'Poppins',
             color: '#8b8c99',
             backgroundColor: props.isSelected ? '#deedff' : 'white' ,
            }),
            singleValue: (baseStyle) => ({
              ...baseStyle,
              marginLeft: '10px',
              color: '#676b7e',
              fontWeight: '500',
            }),
            input: (baseStyle) => ({
              ...baseStyle,
              fontWeight: 'normal',
              fontSize: '1.15rem',
              fontFamily: 'Poppins',
              marginLeft: '10px',
            })
          }}
          className={''}
          isDisabled={props.disabled}
        />
      </div>
    </div>
  );
};


export const FormInputFileV1 = ({
  labelName,
  required = true,
  placeholderText = '',
  ...props
}: IFormInput & FieldHookConfig<File | null>) => {
  const { setFieldValue } = useFormikContext();
  const [fileInput, setFileInput] = useState<File | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFileInput(e.target.files[0]);
      document.getElementById('fileInput')?.setAttribute('data-name', selectedFile.name);
    } else {
      setFileInput(null);
      document.getElementById('fileInput')?.removeAttribute('data-name');
    }
  };

  useEffect(() => {
    if (props.value) {
      setFileInput(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    fileInput ? setFieldValue(props.name, fileInput) : setFieldValue(props.name, null);
  }, [fileInput, props.name, setFieldValue]);

  const [field, meta, helpers] = useField<File | null>(props);

  const fileInputProps = {
    ...field,
    name: props.name,
    id: props.name,
    type: 'file',
    className: errorValidating(meta.error && meta.touched),

    onChange: handleChange,
    onBlur: () => helpers.setTouched(true),
    disabled: props.disabled,
    style: props.disabled ? { backgroundColor: '#e0e0e0' } : { backgroundColor: '#f9f9f9' },
    value: '', 
    'data-name': fileInput?.name,
  };

  return (
    <div className='row mb-6'>
      <InputLabel id={props.name} labelName={labelName} required={required} />
      <div className='col-lg-8 fv-row'>
        <input {...fileInputProps} id="fileInput"/>
        {meta.error && meta.touched && (
          <p className='text-danger m-0 fw-semibold'>{meta.error}</p>
        )}
      </div>
    </div>
  );
};

interface FormValues {
  [key: string]: File | null;
}

export const FormInputImage = ({
  labelName,
  required = true,
  photoValue,
  onValueChange,
  ...props
}: IFormInputImage & FieldHookConfig<File | null>) => {
  const { setFieldValue, errors, touched, setFieldTouched, setFieldError } = useFormikContext<FormValues>();
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        onValueChange?.(selectedFile);
        setFieldValue(props.name, selectedFile);
        setFileUrl(URL.createObjectURL(selectedFile));
      } else {
        if(photoValue){
          setFileUrl(photoValue);
          setFieldValue(props.name, photoValue);
        }else{
          setFileUrl(null);
          setFieldValue(props.name, null);
          setFieldError(props.name, 'Gambar Perlu Diisi');
          setFieldTouched(props.name, true, false);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setFieldError(props.name, 'Gambar Mengunggah File');
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const fileInput = document.getElementById(`fileInput_${props.name}`);
    if (fileInput && !(fileInput as HTMLInputElement).files?.length) {
      (fileInput as HTMLInputElement).style.display = 'block';
      (fileInput as HTMLInputElement).focus();
      event.preventDefault();
    }
  };

  const isError = touched[props.name] && errors[props.name];

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={`row mb-6 ${isError ? 'border-red' : ''}`}>
        {labelName && <InputLabel id={props.name} labelName={labelName} required={required} />}
        <div className='col-lg-8 fv-row'>
          <div className='image-input-wrapper'>
            <label
              className='image-input image-input-outline d-flex justify-content-center align-items-center'
              data-kt-image-input='true'
              style={{
                backgroundImage: fileUrl ? `url(${fileUrl})` : photoValue ? `${photoValue}` : 'none',
                width: '200px',
                height: '230px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                display: 'inline-flex',
                backgroundColor: '#f9f9f9',
                cursor: 'pointer',
                borderRadius: '0px',
                border: isError ? '2px solid #f3416d' : 'none'
              }}
            >
              {(photoValue || fileUrl) && (
                <img
                  src={fileUrl ? fileUrl : photoValue ? photoValue : 'none'}
                  alt=""
                  style={{
                    width: '200px',
                    height: '230px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    display: 'inline-flex',
                    backgroundColor: '#f9f9f9',
                    cursor: 'pointer',
                  }}
                />
              )}
              <input
                id={`fileInput_${props.name}`}
                name={props.name}
                type='file'
                accept='image/*'
                onChange={handleFileChange}
                required={required}
                style={{ display: 'none' }}
                tabIndex={-1}
              />
              {(!photoValue && !fileUrl) && (
                <i className="bi bi-upload fs-1"></i>
              )}
            </label>
          </div>
          {isError && <p className='text-danger m-0 fw-semibold'>{errors[props.name]}</p>}
        </div>
      </div>
    </form>
  );
};

export const FormInputImageV2 = ({
  labelName,
  required = true,
  photoValue,
  setFile,
  disabled = false,
  ...props
}: IFormInputImage & FieldHookConfig<File | null>) => {
  const { setFieldValue, errors, touched, setFieldTouched, setFieldError } = useFormikContext<FormValues>();
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [openCrop, setOpenCrop] = useState<boolean>(false);
  const [cropImageUrl, setCropImageUrl] = useState<string>('');


  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        setFieldValue(props.name, selectedFile);
        setCropImageUrl(URL.createObjectURL(selectedFile));
        setOpenCrop(true);
        event.target.value = '';
      } else {
        console.log(photoValue);
        if(photoValue){
          setPhotoUrl(photoValue);
          setFieldValue(props.name, photoValue);
        }else{
          setPhotoUrl(null);
          setFieldValue(props.name, null);
          setFieldError(props.name, 'Gambar Perlu Diisi');
          setFieldTouched(props.name, true, false);
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setFieldError(props.name, 'Gambar Mengunggah File');
    }
  };

  const isError = touched[props.name] && errors[props.name];

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const fileInput = document.getElementById(`fileInput_${props.name}`);
    if (fileInput && !(fileInput as HTMLInputElement).files?.length) {
      (fileInput as HTMLInputElement).style.display = 'block';
      (fileInput as HTMLInputElement).focus();
      event.preventDefault();
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={`row mb-6 ${isError ? 'border-red' : ''}`}>
        {labelName && <InputLabel id={props.name} labelName={labelName} required={required} />}
        <div className='col-lg-8 fv-row'>
          <div className='image-input-wrapper'>
            <label
              className='image-input image-input-outline d-flex justify-content-center align-items-center'
              data-kt-image-input='true'
              style={{
                backgroundImage: photoUrl ? `url(${photoUrl})` : photoValue ? `${photoValue}` : 'none',
                width: props?.size ? props.size : '230px',
                height: props?.size ? props.size : '230px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                display: 'inline-flex',
                backgroundColor: '#f9f9f9',
                cursor: 'pointer',
                borderRadius: '0px',
                border: isError ? '2px solid #f3416d' : 'none'
              }}
            >
              {(photoValue || photoUrl) && (
                <img
                  src={photoUrl ? photoUrl : photoValue ? photoValue : 'none'}
                  alt=""
                  style={{
                    width: props?.size ? props.size : '230px',
                    height: props?.size ? props.size : '230px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    display: 'inline-flex',
                    backgroundColor: '#f9f9f9',
                    cursor: 'pointer',
                  }}
                  onError={(e) => { 
                    e.currentTarget.src = toAbsoluteUrl('/media/svg/humans/custom-2.svg');
                  }}
                />
              )}
              <input
                id={`fileInput_${props.name}`}
                name={props.name}
                type='file'
                accept='image/*'
                onChange={handleFileChange}
                required={required}
                style={{ display: 'none' }}
                tabIndex={-1}
                disabled={disabled}
              />
              {(!photoValue && !photoUrl) && (
                <i className="bi bi-upload fs-1"></i>
              )}
              {!disabled && (photoValue || photoUrl) && (
                <div className='bg-warning position-absolute bottom-0 end-0  rounded-circle border border-1 border-white p-4 m-2 w-40px h-40px d-flex justify-content-center align-items-center'>
                <i className="bi bi-pencil-fill text-black fs-1"></i>
                </div>
              )}
              
            </label>

          </div>
          {isError && <p className='text-danger m-0 fw-semibold'>{errors[props.name]}</p>}
        </div>
      </div>
    {openCrop && (
      <CropEasy {...{photoUrl: photoUrl ? photoUrl : photoValue,
        cropImageUrl,
        openCrop,
        setOpenCrop,
        setPhotoUrl, 
        setFile : setFile ? setFile : () => {} }}/>
    )}
    </form>
  );
};
