import './AuthContainer.css'

interface AuthContainerProps {
  children: React.ReactNode
}
export default function AuthContainer({children} : AuthContainerProps) {
  return (
    <div className='blank-container'>
    <div className='auth-container' >
      <div className='auth-header'>
        <img src="../../../../assets/img/header-login.png" alt="header-login" />
      </div>
      <div className='auth-form'>
        {children}
      </div>
  
    </div>
  </div>
  )
}