import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getDeviceList, addDevice, getDeviceHistoryById, getDeviceByCriteria, getDeviceByIDList, configRemoteDevice, updateDeviceConfigById, getDevicesList, getDeviceConfigById, deleteDeviceById, updateDevice, unbindDeviceById, addDeviceBatch } from '../api/device'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { Base, BaseQuery } from '../models/base'
import { useGetListData } from './helpers/useGetData'
import { AddDeviceBatchType, AddDeviceType, DeviceType, UpdateConfigIntervalRequest } from '../models/device'
import Swal from 'sweetalert2'
import { useAppContext } from '../../_metronic/helpers/AppContext'
import { getLogProgress, getLogRows } from '../api/personalInfo'
import { useState } from 'react'
export const _DEVICE_LIST = 'device-list'

export const useGetDeviceList = (query: BaseQuery) => {
  return useQuery(
    [_DEVICE_LIST, query],
    async () => {
      if (!query) return await getDeviceList()
      return await getDeviceByCriteria(query)
    },
  )
}

export const useGetDevicesList = (query: BaseQuery) => {
  return useGetListData({
    queryFn: getDevicesList,
    queryKey: _DEVICE_LIST,
    query,
  })
}

export const useGetDeviceByID = (id: number) => {
  return useQuery([_DEVICE_LIST, id],
    async () => {
      if (!id) return
      return await getDeviceByIDList(id)
    })
}

export const useConfigRemoteDevice = (data: { id: number, command: string }) => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id, command }: { id: number, command: string }) => {
      return await configRemoteDevice(id, command)
    },
    {
      onSuccess: (data, variables) => {
        if (variables.command !== "CR" ) {
          toast.success("Perintah Berhasil Dikirim")
        }
        queryClient.invalidateQueries([_DEVICE_LIST])
      },
      onError: (err: AxiosError<Base<DeviceType>>) => {
        toast.error(`${err.response?.data.message}`);
        console.error(err);
        queryClient.invalidateQueries([_DEVICE_LIST]);
      },
    }
  )
}

export const useConfigSetIntervalDevice = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id, data }: { id: string, data: UpdateConfigIntervalRequest }) => {
      return await updateDeviceConfigById(id, data)
    },
    {
      onSuccess: () => {
        toast.success("Interval Berhasil Diupdate")
        queryClient.invalidateQueries([_DEVICE_LIST])
      },
      onError: (err: AxiosError<Base<DeviceType>>) => {
        toast.error(`${err.response?.data.message}`)
        console.error(err)
        queryClient.invalidateQueries([_DEVICE_LIST])
      },
    }
  )
}

export const useGetConfigDevice = (id: string) => {
  return useQuery([_DEVICE_LIST, id], () => getDeviceConfigById(id))
  
}
export const useGetDeviceSOSList = () => {
  return useQuery([_DEVICE_LIST], getDeviceList, {
    staleTime: 300000, select: (data) => {
      return data?.filter((device : DeviceType) => device.sos)
    }
  })
}

export const useAddDevice = () => {
  const queryClient = useQueryClient()
  return useMutation(addDevice, {
    onSuccess: () => {
      toast.success('Perangkat Berhasil Ditambahkan')
      queryClient.invalidateQueries([_DEVICE_LIST])
    },
    onError: (err: AxiosError<Base<DeviceType>>) => {
      toast.error(`${err.response?.data.error}`)
      console.error(err)
    },
  })
}

export const useAddDeviceBatch = () => {
  const { setDeviceImportId } = useAppContext()
  const queryClient = useQueryClient()
  const {mutate, isLoading} = useMutation( 
    async (data: AddDeviceBatchType) => {
      const response = await addDeviceBatch(data)
      setDeviceImportId(response?.data.data?.import_id ?? '');
      return response
    },{
    onSuccess: () => {
      queryClient.invalidateQueries([_DEVICE_LIST])
    },
    onError: (err: AxiosError<Base<DeviceType>>) => {
      Swal.fire({
        icon: 'error',
        title: "Data Gagal Ditambahkan",
        text: `${err.response?.data.error}`,
        showConfirmButton: false,
        timer: 1500,
      })
      console.error('Error add device batch', err)
    }
    }
  )
    return {mutate, isLoading}
  }

export const useGetDeviceLogRow = () => {
  const { setDataDeviceRows } = useAppContext()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(
    async ({id}: {id: string}) => {
      const response = await getLogRows(id)
      setDataDeviceRows(response?.data?.imported_rows)
      return response
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([_DEVICE_LIST])
      },
      onError: (err: AxiosError<Base<DeviceType>>) => {
        console.error('Error get device log row', err)
        queryClient.invalidateQueries([_DEVICE_LIST])
      }
    }
  )
  return { mutate, isLoading}
}

export const useGetDeviceLogProggress = () => {
  const queryClient = useQueryClient()
  const [toast, setToast] = useState({isOpen: false, message: '', type: 'pending'})
  const {mutate, isLoading} = useMutation(
    async ({id}: {id: string}) => {
      const result = await getLogProgress(id)
      return result
    },
    {
      onSuccess: (result, {id}) => {
        queryClient.invalidateQueries([_DEVICE_LIST])
        const { status } = result.data;
        let message: string, type: string;
        if (status === 'in_progress') {
          const interval = setInterval(async () => {
            const updatedResult = await getLogProgress(id)
            const { imported_count, failed_count, total_rows, status } = updatedResult.data
            if (status === 'completed') {
              clearInterval(interval)
              if (failed_count === total_rows) {
                message = `${failed_count} dari ${total_rows} Perangkat Tersimpan`;
                type = 'error'
              } else if (imported_count === total_rows) {
                message = `${imported_count} dari ${total_rows} Perangkat Tersimpan`;
                type = 'success';
              }
              else if (imported_count >= 0) {
                message = `${imported_count} dari ${total_rows} Perangkat Tersimpan`;
                type = 'warning'
              } 
              setToast({
                isOpen: true,
                message: message,
                type: type
              })
            } else {
              setToast({
                isOpen: true,
                message: `${imported_count} dari ${total_rows} Perangkat Tersimpan`,
                type: 'pending',
              })
            }
          }, parseInt(process.env.REACT_APP_INTERVAL_BATCH_UPLOADS as string))
        } else if (status === 'completed') {
          queryClient.invalidateQueries([_DEVICE_LIST])
          if (result.data.failed_count === result.data.total_rows) {
            setToast({
              isOpen: true,
              message: `0 dari ${result.data.total_rows} Perangkat Tersimpan`,
              type: 'error',
            })
          } else if (result.data.imported_count === result.data.total_rows) {
            setToast({
              isOpen: true,
              message: `${result.data.imported_count} dari ${result.data.total_rows} Perangkat Tersimpan`,
              type: 'success',
          }) 
          } else if (result.data.imported_count >= 0) {
              setToast({
                isOpen: true,
                message: result.data.total_rows > 0 ? `${result.data.imported_count} dari ${result.data.total_rows} Perangkat Tersimpan` : 'Gagal mengimport data',
                type: result.data.total_rows > 0 ? 'warning' : 'error',
            })
          } 
        }
      },
      onError: (err) => {
        console.error('Error -> ', err)
        queryClient.invalidateQueries(['_DEVICE_LIST'])
        setToast({
          isOpen: true,
          message: 'Gagal mengimport data',
          type: 'error',
        })
      },
    }
  )
  return {mutate, isLoading, toast, setToast}
}

interface GetDeviceHistoryType {
  id?: string
  date?: {
    year: number
    month: number
    day: number
  }
}

export const useGetDeviceHistory = ({ id, date }: GetDeviceHistoryType) => {
  const strDate =
    typeof date !== 'undefined' ? `${date.year}-${date?.month}-${date?.day}` : undefined
  return useQuery(
    ['device-history', strDate],
    () => {
      if (typeof id === 'undefined') return undefined
      return getDeviceHistoryById({ id, date: strDate })
    },
    { keepPreviousData: true }
  )
}

export const useDeleteDevice = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      return await deleteDeviceById(id)
    },{
    onSuccess: () => {
      toast.success('Perangkat Berhasil Dihapus')
      queryClient.invalidateQueries([_DEVICE_LIST])
    },
    onError: (err: AxiosError<Base<DeviceType>>) => {
      toast.error(`${err.response?.data.message}`)
      console.error(err)
    },
  }
  )
}

export const useUpdateDevice = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async ({ id, data }: { id: number, data: AddDeviceType }) => {
      return await updateDevice(id, data)
    },
    {
      onSuccess: () => {
        toast.success('Perangkat Berhasil Diupdate')
        queryClient.invalidateQueries([_DEVICE_LIST])
      },
      onError: (err: AxiosError<Base<DeviceType>>) => {
        toast.error(`${err.response?.data.error}`)
        console.error(err)
      },
    }
  )
}


export const useUnbindDeviceById= () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      return await unbindDeviceById(id)
    },
    {
      onSuccess: () => {
        toast.success('Perangkat Berhasil Diputuskan')
        queryClient.invalidateQueries([_DEVICE_LIST])
      },
      onError: (err: AxiosError<Base<DeviceType>>) => {
        toast.error(`${err.response?.data.message}`)
        console.error(err)
      },
    }
  )
}