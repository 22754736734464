import {KTIcon} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
// TODO : Implement functional buttons

interface IFormFooterBase {
  linkTo?: string
  backText?: string
  confirmText?: string
  confirmDisable?: boolean
  withBackButton?: boolean
  containerClassName?: string
  isloading?: boolean
  hitClickBack?: () => void

}

export const FormFooterBase = ({
  linkTo = '#',
  backText = 'Kembali',
  confirmText = 'Tambah',
  confirmDisable = false,
  withBackButton = true,
  containerClassName = 'mb-6 px-9',
  hitClickBack = () => {},
  isloading = false
}: IFormFooterBase) => {
  return (
    <div className={`row ${containerClassName}`}>
      <div className='fv-row d-flex justify-content-end'>
        {withBackButton && (
          <Link to={linkTo} className='btn btn-light me-3' onClick={hitClickBack}>
            {linkTo !== '#' && (
              <KTIcon iconName='bi bi-chevron-compact-left' className='fs-2' />
            )}
            {backText}
          </Link>
        )}
        <button type='submit' className={`btn me-3 flex align-items-center ${confirmDisable ? 'btn-light' : 'btn-primary'}`} disabled={confirmDisable}>
          {!isloading ? (
            <>
              <img
                src={`${toAbsoluteUrl('/media/icons/icSave.png')}`}
                width={20}
                className='me-2'
                alt='confirm button'
                style={confirmDisable ? {
                  filter: 'invert(99%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(99%) contrast(100%)',
                } : {}}                
              />
              {confirmText}
            </>
          ) : (
            <span className='indicator-progress' style={{display: 'block'}}>
            {confirmText}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}
