/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {KTIcon} from '../../../../_metronic/helpers'
import {AuthModel} from '../core/_models'
import {parseJwt} from '../../../utils/converter'
import {useAuth} from '../core/Auth'
import {AxiosError, isAxiosError} from 'axios'
import { loginV2 } from '../../../api/auth'
import './AuthContainer.css'
import { Link } from 'react-router-dom'
import AuthContainer from './AuthContainer'

const initialValues = {
  email: '',
  password: '',
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(6, 'Minimal 6 karakter')
    .max(50, 'Maksimal 30 karakter')
    .required('Harap masukan email atau username'),
  password: Yup.string()
  .min(6, 'Minimal 6 karakter')
  .max(50, 'Maksimal 30 karakter')
  .required('Harap masukan password'),
})

export function Login() {
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      try {
      const {data} = await loginV2(values);
        const authInfo: AuthModel = {
          ...data.data,
          email: values.email,
      };
        saveAuth(authInfo)
        const parseData = parseJwt(authInfo)
        setCurrentUser(parseData)
      } catch (error : AxiosError<any> | any) {
        if (isAxiosError(error) && error.response) {
          const message = error.response?.data.error
          formik.setErrors({email: message, password: message})
        } else {
          setLoginError('Terjadi kesalahan saat login. Silahkan coba beberapa saat lagi.')
        }
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <AuthContainer>
      <form
      className='form-container'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className=''>
        <h1 className='text-dark fw-bold'>Selamat Datang</h1>
        {loginError && <div className='text-danger fw-bold fs-6'>{loginError}</div>}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : null}
      
      {/* begin::Form group */}
      <div className='fv-row'>
        <label className='form-label fs-6 fw-bold text-dark'>Username / Email</label>
        <input
          placeholder='Masukkan username / email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'border border-danger': formik.touched.email && formik.errors.email},
            {
              'border border-success': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row'>
        <div className='d-flex justify-content-between '>
          <label className='form-label fw-bold text-dark fs-6'>Kata Sandi</label>
          <Link to="/auth/forgot-password" className='link-primary fw-bold'>Lupa Kata Sandi ?</Link>
        </div>
        <div className='position-relative'>
          <input
            placeholder='Masukkan kata sandi'
            type={`${showPassword ? 'text' : 'password'}`}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border border-danger': formik.touched.password && formik.errors.password,
              },
              {
                'border border-success': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div
            role='button'
            onClick={() => setShowPassword(!showPassword)}
            className='position-absolute end-0 top-25 mx-5'
          >
            <KTIcon className='fs-2' iconName={`bi ${showPassword ? 'bi-eye-slash' : 'bi-eye'}`} />
          </div>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-flex '>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary w-100'
        >
          {!loading && <span className='indicator-label'>Masuk</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      </form>
    </AuthContainer>
  )
}
