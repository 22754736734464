import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Link } from "react-router-dom";

interface ErrorPageContainerProps {
  imageUrl: string;
  title: string;
  children: ReactJSXElement
}

const ErrorPageContainer = ({imageUrl, title, children} : ErrorPageContainerProps) => {
  return (
    <div className='d-flex flex-column w-100 h-100 align-items-center justify-content-center gap-4'>
      <div className="p-8 text-center">
        <img src={imageUrl} className="img-fluid" alt="404" />
      </div>
      <div className="d-flex flex-column gap-4">
        <div>
          <h1 className='fw-bolder text-center'>{title}</h1>
          {children}
        </div>
        <Link className='btn btn-primary w-100' to='/'>Kembali ke Halaman Utama</Link>
      </div>
    </div>
  );
}

export default ErrorPageContainer